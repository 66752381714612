export default {
  getProductCart (state) {
    return state.cart
  },
  checkIfProductInCart: state => payload => {
    const findIndexOfStore = state.cart.findIndex(storeData => {
      return storeData.store_info.id === payload.store_id
    })
    if (findIndexOfStore > -1) {
      const ifProductExistInCart = state.cart[findIndexOfStore].store_products.findIndex(product => {
        return product.id === payload.id
      })
      return ifProductExistInCart > -1
    } else {
      return false
    }
  },
  getStoreDetailsAndProducts: state => storeId => {
    const findIndexOfStore = state.cart.findIndex(storeData => {
      // eslint-disable-next-line eqeqeq
      return storeData.store_info.id == storeId
    })
    if (findIndexOfStore > -1) {
      return state.cart[findIndexOfStore]
    }
  },
  getProductFavourite (state) {
    return state.favourite
  },
  checkProductInFavourite: state => payload => {
    const findIndexOfProduct = state.favourite.findIndex(item => {
      return item.id === payload.id
    })
    if (findIndexOfProduct > -1) {
      return true
    } else {
      return false
    }
  },
  getTotalProduct  (state) {
    let totalProduct = 0
    state.cart.forEach(data => {
      totalProduct += data.store_products.length
    })
    return totalProduct
  }
}
