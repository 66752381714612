/* import Api from '@/axios' */
import { marketPlace, website } from '@/axios'

export default {
  getAllCities () {
    return marketPlace().get('cities')
  },
  getAllContries () {
    return marketPlace().get('countries')
  },
  getGlobalSearch (data) {
    return website().post('user/search', data)
  }
}
