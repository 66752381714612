export default {
  home: 'الرئيسية',
  statistics: 'إحصائيات',
  Permissions: 'التصاريح',
  insurance: 'التأمين',
  transportation: 'النقل',
  veterinary: 'البيطرة',
  events: 'الفعاليات',
  marketplace: 'المتاجر',
  wallet: 'المحفظة المالية',
  favourite: 'المفضلة',
  comments: 'تعليقاتي',
  settings: 'الإعدادات',
  insuranceDetails: 'تفاصيل التأمين',
  insuranceNow: ' امن الآن',
  transportationNow: 'اطلب رحلة',
  transportationDetails: 'تفاصيل النقل',
  shopping: 'التسوق',
  orders: 'الطلبات',
  searchHere: 'ابحث هنا',
  company: 'الشركة',
  status: 'الحالة',
  lastEnter: 'اخر دخول بتاريخ',
  documented: 'موثق',
  veterinaryDetails: 'تفاصيل البيطرة',
  emptyFavourite: 'لا يوجد لديك مفضلة حتى الأن',
  goHomepage: 'توجه إلى الصفحة الرئيسية',
  paymentErrorMsg: 'حدث خطأ أثناء الدفع',
  successPayment: 'تم الدفع بنجاح',
  reservations: 'الحجوزات',
  wallet_balance: 'رصيد المحفظة'
}
