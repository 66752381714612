// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')
const authLayout = () => import('@/layouts/authLayout')

const login = () => import('./views/login')
const registration = () => import('./views/registraion')
const otb = () => import('./views/otb')
const forgotPassword = () => import('./views/forgotPassword')
const resetPassword = () => import('./views/resetPassword')
const verifyResetPassword = () => import('./views/verifyResetPasswordToken')

// start Routes
export default [
  {
    path: '/auth',
    name: 'auth',
    component: websiteLayout,
    children: [
      {
        path: '',
        name: 'auth',
        meta: {
          name: 'auth',
          userType: '',
          permission: '',
          breadCrumb: [
          ]
        },
        component: authLayout,
        children: [
          {
            path: 'login',
            name: 'login',
            meta: {
              name: 'login',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: login
          }, {
            path: 'registration',
            name: 'registration',
            meta: {
              name: 'registration',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: registration
          },
          {
            path: 'otb',
            name: 'otb',
            props: true,
            meta: {
              name: 'otb',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: otb
          },
          {
            path: 'forgot-password',
            name: 'forgotPassword',
            meta: {
              name: 'forgotPassword',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: forgotPassword
          },
          {
            path: 'verify-reset-password',
            name: 'verifyResetPassword',
            meta: {
              name: 'verifyResetPassword',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: verifyResetPassword
          },
          {
            path: 'reset-password',
            name: 'resetPassword',
            meta: {
              name: 'resetPassword',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: resetPassword
          }
        ]
      }
    ]
  }
]
