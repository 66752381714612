/* eslint-disable*/ 
export default {
  home: 'Home',
  mainPage: 'Home',
  images: 'images',
  videos: 'videos',
  aboutUs: 'about us',
  services: 'services',
  mediaCenter: 'media center',
  contactUs: 'contact us',
  searchInAllDepartments: 'search In All Departments',
  shops: 'shops',
  permissions: 'permissions',
  permissionsInfo: 'Soon, you will obtain permits for camels easily through our platform',
  insurance: 'insurance',
  insuranceInfo: 'Now, insure the transportation of your camels and receive the insurance policy.',
  transport: 'transportation',
  transportInfo: 'Now, transport your camels inside the Kingdom of Saudi Arabia.',
  veterinarian: 'veterinarian',
  veterinaryInfo: 'We provide you with certified doctors for the convenience of your camels.',
  camelTrade: 'camel trade',
  events: 'events',
  statistics: 'statistics',
  InsurancePolicies: 'Insurance policies',
  purchases: 'purchases',
  transportationsProcess: 'transportation',
  merchants: 'merchants',
  productsCount: 'products',
  doctorsCount: 'doctors',
  footer: 'The Ibbil platform is a platform specialized in providing all services related to camels in the Kingdom of Saudi Arabia, including insurance, transportation and permit services, feed stores, food and camel supplies, and veterinary services.',
  termsConditions: 'termsConditions',
  aboutStores: 'about stores',
  allStores: 'all stores',
  camelStores: 'camel stores',
  feedStores: 'feed stores',
  camelProductsStores: 'camel products stores',
  animalFoodStores: 'animal food stores',
  camelSuppliesStores: 'camel supplies stores',
  electronicChipStores: 'electronic chip stores',
  aboutPermits: 'about permits',
  getApermit: 'get a permit',
  permissionVerification: 'permission verification',
  aboutInsuranceServices: 'about insurance services',
  getInsurance: 'get insurance',
  aboutMovingCamels: 'about moving camels',
  submitAtransferRequest: 'submit a transfer request',
  aboutVaterinaryServices: 'about vaterinary services',
  clinics: 'clinics',
  theDoctors: 'the doctors',
  appointmentBooking: 'appointment Booking',
  tradingCamels: 'trading camels',
  camelBreeders: 'camel breeders',
  camelSales: 'camel sales',
  camelRentalAndSharing: 'camel rental and sharing',
  aboutEvents: 'about events',
  eventsSchedule: 'events schedule',
  joinAsAmerchant: 'join as a merchant or service provider',
  whoAreYou: 'who are you',
  theEbelPlatform: 'The Ebel platform is a platform specialized in providing all services related to camels in the Kingdom of Saudi Arabia, including insurance, transportation and permit services, feed stores, camel supplies and veterinary services, including insurance, transportation and permit services, feed stores, food and supplies, camel supplies and veterinary services. It is also considered a trading and permit platform, and feed stores. All services related to camels in the Kingdom of Saudi Arabia',
  signIn: 'sign in',
  register: 'Register',
  account: 'Don`t have an account? Create a new account',
  IdNumber: 'ID',
  fullNAme: 'Full Name',
  phoneNUmber: 'Phone Number',
  password: 'Password',
  storeType: 'Store type',
  storeNameInArabic: 'Store Name In Arabic',
  storeNameInEnglish:'Store Name In English',
  theCity:'the City',
  commissionMessage: "Please note that a commission of {percentage}% will be applied to the value of each order.",
  storeAddress: 'Store Address',
  news: 'News',
  aboutUsInfo: 'The IBBIL platform is the first platform to provide an integrated commercial market for all services related to camels in the Kingdom of Saudi Arabia, including for all services related to camels in the Kingdom of Saudi Arabia, including insurance, transportation, permit services, food stores, camel supplies and veterinary services, in addition to trading, renting and sharing camels. You can enjoy shopping in many different stores and benefit from all the services provided by the various merchants, doctors and shop owners who offer their services on IBBIL platform.',
  dontHaveAccount: 'dont have account',
  eventAddress: 'event address',
  eventDescription: 'Description of this event is random text',
  seeAll: 'see all',
  userProfile: 'profile',
  continue: 'continue',
  back: 'back',
  rs: 'SAR',
  termsCondition: 'terms and conditions',
  privacyPolicy: 'privacy policy',
  reserved: '©All rights reserved to the IBBIl platform 2022',
  ourServices: 'Our services',
  more: 'more',
  photoGallery: 'Photo Gallery',
  videoGallery: 'Video Gallery'
}
