export default {
  changeCartInLocalStorage (state) {
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  deleteAllStoreInCart (state, payload) {
    const findIndexOfStore = state.cart.findIndex(storeData => {
      return storeData.store_info.id === payload.store_info.id
    })
    if (findIndexOfStore > -1) {
      state.cart.splice(findIndexOfStore, 1)
      localStorage.setItem('cart', JSON.stringify(state.cart))
    }
  },
  changeSelectedStore (state, payload) {
    state.selectedStore = payload
    localStorage.setItem('selectedStore', JSON.stringify(payload))
  },
  calculateStoreFees (state, findIndexOfStore) {
    // eslint-disable-next-line no-unused-vars
    let allFees = 0
    state.cart[findIndexOfStore].store_products.forEach(product => {
      allFees += Number(product.totalProductPrice)
    })
    state.cart[findIndexOfStore].store_info.totalFees = Number(allFees)
  },
  calculateStoreFeesWithoutTax (state, findIndexOfStore) {
    // eslint-disable-next-line no-unused-vars
    let allFees = 0
    state.cart[findIndexOfStore].store_products.forEach(product => {
      allFees += Number((product.price_info.final_price - product.price_info.tax) * product.quantityInCart)
    })
    state.cart[findIndexOfStore].store_info.totalFessWithOutTax = Number(allFees)
  },
  changeFavouriteInLocalStorage (state) {
    localStorage.setItem('favourite', JSON.stringify(state.favourite))
  }
}
