import { transportation } from '@/axios'

export default {
  getAllCategoryCar () {
    return transportation().get('allowed-vehicle-types?filters[status]=1')
  },
  requestTrip (payload) {
    return transportation().post('trip-requests', payload)
  },
  cancelTrip (id, reason) {
    return transportation().patch(`trip-requests/${id}/cancel`, { reason: reason })
  },
  closeTrip (id) {
    return transportation().patch(`trips/${id}/cancel`)
  },
  getVehicleInfo (id) {
    return transportation().get(`vehicles/${id}`)
  },
  updateOfferStatus (offerId, requestId, status, distance) {
    return transportation().patch(`offers/${offerId}`, { tripRequestId: requestId, status: status, distance: distance })
  },
  pay (id, body) {
    return transportation().patch(`trips/${id}/pay`, body)
  },
  updateFcmToken (payload) {
    return transportation().patch('users/fcm-token', { fcm_token: payload })
  }
}
